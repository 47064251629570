import React, { ReactNode } from 'react';
import loadable from '@loadable/component';

import styles from './QuickToSetUpSection.module.scss';
import HorizontalLine from '../media/quick-to-setup-horizontal-line.svg';
import { Section } from '../../../components/Page';
import CallToAction from '../../../components/CallToAction';
import Grid from '../../../components/layout/Grid';
import Flex from '../../../components/layout/Flex';
import SectionHeading from '../../../components/SectionHeading';

const AddMissions = loadable(() => import('../media/add-missions.svg'));
const GooseTime = loadable(() => import('../media/goose-time.svg'));
const InviteParticipants = loadable(() => import('../media/invite-participants.svg'));
const CreateYourGoosechase = loadable(() => import('../media/create-your-goosechase.svg'));
const TrackResults = loadable(() => import('../media/track-results.svg'));

const QuickToSetUpSection = () => {
  const createYourGameItem = (
    <SetupItem
      className={styles.createYourGameStep}
      icon={<CreateYourGoosechase className={styles.stepIcon} />}
      title={
        <>
          Create your
          <br />
          Experience
        </>
      }
      desc="Set up in just a few clicks!"
    />
  );
  const addMissionsItem = (
    <SetupItem
      className={styles.addMissionsStep}
      icon={<AddMissions className={styles.stepIcon} />}
      title={
        <>
          Add
          <br />
          Missions
        </>
      }
      desc="Make your own or choose from ours"
    />
  );
  const invitePlayersItem = (
    <SetupItem
      className={styles.inviteParticipantsStep}
      icon={<InviteParticipants className={styles.stepIcon} />}
      title={
        <>
          Invite your
          <br />
          participants
        </>
      }
      desc="Take part in teams or as individuals"
    />
  );
  const gameBeginsItem = (
    <SetupItem
      className={styles.gameBeginsStep}
      icon={<GooseTime className={styles.stepIcon} />}
      title={
        <>
          It’s GOose
          <br />
          time
        </>
      }
      desc="Participants complete Missions in the app"
    />
  );
  const seeProgressItem = (
    <SetupItem
      className={styles.seeProgressStep}
      icon={<TrackResults className={styles.stepIcon} />}
      title={
        <>
          Track live
          <br />
          results
        </>
      }
      desc="View stats and participant engagement"
    />
  );

  return (
    <Section className={styles.quickToSetUpSection}>
      <SectionHeading title={['Quick to set up,', 'easy to play']} />
      <div className={styles.stepsContainer}>
        <Grid container spacing={0}>
          <Grid item xs={12} md="flex">
            {createYourGameItem}
          </Grid>
          <Grid item xs={12} md="flex">
            {addMissionsItem}
          </Grid>
          <Grid item xs={12} md="flex">
            {invitePlayersItem}
          </Grid>
          <Grid item xs={12} md="flex">
            {gameBeginsItem}
          </Grid>
          <Grid item xs={12} md="flex">
            {seeProgressItem}
          </Grid>
        </Grid>
      </div>
      <Flex className={styles.smStepsContainer} alignItems="stretch" justifyContent="space-between">
        <div>
          {createYourGameItem}
          {seeProgressItem}
        </div>
        <div>
          {addMissionsItem}
          {gameBeginsItem}
        </div>
        <div>{invitePlayersItem}</div>
      </Flex>
      <HorizontalLine className={styles.guidelineHorizontal} />
      <div className={styles.setupCTAWrapper}>
        <CallToAction />
      </div>
    </Section>
  );
};

const SetupItem = (
  props: React.PropsWithChildren<{
    icon: ReactNode;
    title: ReactNode;
    desc: string;
    pathImg?: ReactNode;
    className?: string;
  }>
) => (
  <div className={props.className}>
    {props.icon}
    <h3 className={styles.innerTitle}>{props.title}</h3>
    <p className={styles.itemDesc}>{props.desc}</p>
    {props.pathImg}
  </div>
);

export default QuickToSetUpSection;
